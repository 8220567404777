import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import WhyUsPage from '../ui/pages/WhyUsPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const HowItWorks = props => (
  <>
    <HelmetComponent
      title={metaTags.whyUsTitle}
      description={metaTags.whyUs}
      page="de/why-us"
    />
    <AppWrapper {...props} lang="de">
      <WhyUsPage />
    </AppWrapper>
  </>
)

export default HowItWorks
